import * as _ from "lodash";
import * as pako from "pako";
import * as base64 from "base-64";
import CryptoJS from "crypto-js";
import { format, isValid } from "date-fns";

import { FRONT_DATE_TIME_PATTERN, KEY_ENCRIPTION, FRONT_DATE_PATTERN, DATE_PATTERN} from "./Constants";
import DecimalFormat from "./DecimalFormat";
import { createStringDateAsUTC, safeFormattingDate } from "./UtilDates";

//Makes bearer token by token passed by param
export const getBearerToken = (token) => {
  return "Bearer " + token;
};

//Get Route prefix by solution
export const getRouteBySolution = (routes, solution) => {
  if (_.isNil(routes)) {
    return null;
  } else {
    const prefix = routes.find((r) => r.appName === solution)?.prefix;
    return prefix;
  }
};

//Transform text
export const getTransformedText = (text, prop) => {
  if (_.isNil(prop)) return text;
  const stdProp = prop.toUpperCase();
  if (stdProp === "UPPERCASE") {
    return text.toUpperCase();
  } else if (stdProp === "LOWERCASE") {
    return text.toLowerCase();
  } else {
    return text;
  }
};

//TRUE if maxLength is reached
export function isMaxLengthReached(value, def) {
  let result = false;
  if (def && def.maxLength && value && value.length > def.maxLength) {
    result = true;
  }
  return result;
}

//Get transformed Text value by def
export function getTransformedTextByDef(value, def) {
  let result = value;
  if (!_.isNil(def) && !_.isNil(def.textTransform)) {
    const { textTransform } = def;
    if (textTransform === "uppercase") {
      result = result.toUpperCase();
    } else if (textTransform === "lowercase") {
      result = result.toLowerCase();
    }
  }
  return result;
}

//Returns ordered object BY KEY e.g: {1: "one", 3:"three", 2: "two"} -> {1: "one", 2: "two", 3:"three"}
export const getOrderedObjectByKey = (obj) => {
  return (
    Object.entries(obj)
      .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
      // eslint-disable-next-line
      .reduce((o, [k, v]) => ((o[k] = v), o), {})
  );
};

//Get next focuseable input based on columns focused index
export const getNextFocuseableInput = (focuseableInputs, field) => {
  const actualFocus = _.findKey(focuseableInputs, function (o) {
    return o === field;
  });
  let result = actualFocus;
  let keys = Object.keys(focuseableInputs);
  let nextIndex = keys.indexOf(actualFocus) + 1;
  if (!_.isNil(keys[nextIndex]) && !_.isNaN(keys[nextIndex])) {
    //If i have next sequence item go there
    result = Number(keys[nextIndex]);
  } else {
    //if not, get focus on the first input of row
    result = Number(Object.keys(focuseableInputs)[0]);
  }
  return result;
};

const MAP_INIT_LAST_CHAR = [
  {
    ini: "{",
    last: "}",
  },
  {
    ini: "[",
    last: "]",
  },
];

/**
 * Final method,uncompress GZIP base64 encoded into JSON
 * Uses pako.js and atob with Uint8Array, faster combination found
 * @param {*} gzipped
 * @returns
 */
export function finalUncompressGZIP(gzipped) {
  // Base64 String -> bytes
  //const b64Decoded = atob(gzipped);
  const b64Decoded = base64.decode(gzipped);

  // Split into array
  const byteArray = b64Decoded.split("").map(function (x) {
    return x.charCodeAt(0);
  });

  // Convert to bynary model necessary to inflate
  const binData = new Uint8Array(byteArray);

  // Decompress gzip
  const unzipped = pako.ungzip(binData, { to: "string" });
  //console.log("unzipped", unzipped.substring(0, 10));
  if (unzipped && unzipped.length > 0) {
    return JSON.parse(unzipped);
  } else {
    return null;
  }
}

// Uncompress data from server to JS
// ATOB and Uint8Array
export function uncompressGZIP(gzipped) {
  // Base64 String -> bytes
  const b64Decoded = atob(gzipped);

  // Split into array
  const byteArray = b64Decoded.split("").map(function (x) {
    return x.charCodeAt(0);
  });

  // Convert to bynary model necessary to inflate
  const binData = new Uint8Array(byteArray);

  // Decompress gzip
  const unzipped = pako.ungzip(binData, { to: "string" });
  if (unzipped && unzipped.length > 0) {
    try {
      const startOfArray = unzipped.indexOf("[");
      const startOfObject = unzipped.indexOf("{");
      const startEntity =
        startOfArray <= startOfObject ? startOfArray : startOfObject;
      if (_.isNil(startEntity) || startEntity >= unzipped.length) {
        return null;
      } else {
        const firstRealCharacter = unzipped.charAt(startEntity);
        const lastCharacter = unzipped.charAt(unzipped.length - 1);
        const necessaryStart = MAP_INIT_LAST_CHAR.find(
          (x) => x.last === lastCharacter
        )?.ini;
        if (necessaryStart) {
          const sub = unzipped.substring(startEntity);
          const finalToParse =
            necessaryStart !== firstRealCharacter
              ? `${necessaryStart}${sub}`
              : sub;
          const result = JSON.parse(finalToParse);
          return result;
        } else {
          return null;
        }
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  }
}

// Uncompress data from server to JS -- Version 2
// base64 and Uint16Array
export function uncompressGZIP2(gzipped) {
  // Base64 String -> bytes
  const b64Decoded = base64.decode(gzipped);

  //console.log("b64Decoded", b64Decoded);

  // Split into array
  const byteArray = b64Decoded.split("").map(function (x) {
    return x.charCodeAt(0);
  });

  // Convert to bynary model necessary to inflate
  const binData = new Uint16Array(byteArray);

  // Decompress gzip
  const unzipped = pako.ungzip(binData, { to: "string" });
  //console.log("unzipped", unzipped);
  if (unzipped && unzipped.length > 0) {
    try {
      const startOfArray = unzipped.indexOf("[");
      const startOfObject = unzipped.indexOf("{");
      const startEntity =
        startOfArray <= startOfObject ? startOfArray : startOfObject;
      if (_.isNil(startEntity) || startEntity >= unzipped.length) {
        return null;
      } else {
        const firstRealCharacter = unzipped.charAt(startEntity);
        const lastCharacter = unzipped.charAt(unzipped.length - 1);
        //console.log("LAST CHARACTER", lastCharacter);
        const necessaryStart = MAP_INIT_LAST_CHAR.find(
          (x) => x.last === lastCharacter
        )?.ini;
        //console.log("necessaryStart", necessaryStart);
        if (necessaryStart) {
          const sub = unzipped.substring(startEntity);
          const finalToParse =
            necessaryStart !== firstRealCharacter
              ? `${necessaryStart}${sub}`
              : sub;
          //console.log("finalToParse", finalToParse);
          const result = JSON.parse(finalToParse);
          return result;
        } else {
          return null;
        }
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  }
}

export function uncompressGZIP3(gzipped) {
  var zlibBinData = atob(gzipped);
  var zlibCharData = zlibBinData.split("").map(function (e) {
    return e.charCodeAt(0);
  });
  var binData = new Uint8Array(zlibCharData);
  var data = pako.inflate(binData);
  var toReturn = String.fromCharCode.apply(null, new Uint16Array(data));
  return toReturn;
}

// Get N positions start-N of number or string
export function getFirstNPos(n, obj) {
  if (typeof obj === "string") {
    if (n <= obj.length) {
      return obj.substring(0, n);
    } else {
      return obj;
    }
  } else if (typeof obj === "number") {
    const objSt = obj.toString();
    if (n <= objSt.length) {
      const sub = objSt.substring(0, n);
      return parseInt(sub);
    } else {
      return obj;
    }
  } else {
    return obj;
  }
}

export function isJsonFormat(toAnalyze) {
  try {
    let parsed = JSON.parse(toAnalyze);
    if (parsed) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}

export function parseToJsonIfPosible(toConvert) {
  try {
    let parsed = JSON.parse(toConvert);
    return parsed;
  } catch (e) {
    return {};
  }
}

export function base64ToArrayBuffer(base64) {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

export function saveByteArrayToFile(reportName, byte) {
  var blob = new Blob([byte], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  var link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  var fileName = reportName;
  link.download = fileName;
  link.click();
}

export function saveByteArrayToFilePDF(reportName, byte) {
  var blob = new Blob([byte], {
    type: "application/pdf",
  });
  var link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  var fileName = reportName;
  link.download = fileName;
  link.click();
}

export function encryptPassword(password) {
  const encrypted = CryptoJS.AES.encrypt(password, KEY_ENCRIPTION);
  const result = encrypted.toString();
  return result;
}

export function decryptString(encrypted) {
  const decrypted = CryptoJS.AES.decrypt(encrypted, KEY_ENCRIPTION);
  const result = decrypted.toString(CryptoJS.enc.Utf8);
  return result;
}

export function getMatchesFromStringRegex(string, regex) {
  const result = [];
  let m;

  while ((m = regex.exec(string)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    // The result can be accessed through the `m`-variable.
    m.forEach((match) => {
      if (match !== null && "" !== match) {
        result.push(match);
      }
    });
  }

  return result;
}

export function getPropertyValue(obj, dataToRetrieve) {
  return dataToRetrieve.split(".").reduce(function (o, k) {
    return o && o[k];
  }, obj);
}

export function makeAcceptedFormatFiles(formatFiles) {
  let result = "";
  if (formatFiles) {
    Object.values(formatFiles).forEach((f) => {
      if (f && f.mimeType) {
        result += `${f.mimeType}, `;
      }
    });
  }
  return result;
}

export const flattenMenuItems = (members) => {
  let children = [];
  const flattenMembers = members.map((m) => {
    let innerChildren = [];
    if (m.children && m.children.length) {
      innerChildren = m.children.map((x) => {
        return {
          ...x,
          headerImg:
            !_.isNil(m?.headerImg) && m.headerImg !== "" ? m.headerImg : null,
          parent:
            !_.isNil(m?.parent) && !_.isEmpty(m.parent) && _.isArray(m.parent)
              ? [...m.parent, x.propText]
              : [m.propText],
        };
      });

      if (innerChildren && innerChildren.length > 0) {
        children = [...children, ...innerChildren];
      } else {
        children = [...children, ...m.children];
      }
    }
    return m;
  });

  return flattenMembers
    .concat(children.length ? flattenMenuItems(children) : children)
    .filter((oi) => oi && !oi?.children?.length && oi.route && oi.route !== "");
};

export function resolveRelativePath(schema, globalFormData) {
  let result = "";
  if (schema && schema.relativeFilePath) {
    const { relativeFilePath } = schema;
    if (relativeFilePath !== null && relativeFilePath !== "") {
      result = relativeFilePath;
      const regex = /\B@[a-zA-Z0-9.]+/gm;
      const matches = getMatchesFromStringRegex(relativeFilePath, regex);
      if (matches !== null && matches.length > 0) {
        for (let i = 0; i < matches.length; i++) {
          let varToSust = matches[i];
          if (varToSust) {
            varToSust = varToSust.replace("@", "");
            const actualValueInForm =
              globalFormData && _.get(globalFormData, varToSust);
            if (actualValueInForm) {
              const { value } = actualValueInForm;
              result = result.replace(matches[i], value || "-");
            }
          }
        }
      }
    }
  }
  return result;
}

/**
 * Function to validate password strength
 * At least 8 characters long
 * 1 uppercase
 * 1 number
 * 1 special character
 * @param {*} password
 * @returns
 */
/* eslint-disable */
export function validatePasswordStrength(password) {
  if (password === null || password === undefined || password === "") {
    return true;
  }

  if (
    password.length >= 8 &&
    password.match(/[A-Z]/) &&
    password.match(/[0-9]/) &&
    password.match(/[!@#$%^&*()_+{}|:"<>?~`\-=\[\]\\;',./]/)
  ) {
    return true;
  }

  return false;
}
/* eslint-enable */

//Function to check if email is valid
export function validateEmail(email) {
  if (email === null || email === undefined || email === "") {
    return true;
  }

  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
}

//Function to flat nested objects inside parent object
export function flatRowsToExcel(obj, prefix = "") {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    if (value && typeof value === "object") {
      return { ...acc, ...flatRowsToExcel(value, `${prefix}${key}.`) };
    }
    return { ...acc, [`${prefix}${key}`]: value };
  }, {});
}

export function splitArrayIntoChunks(array, chunkSize) {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunks.push(chunk);
  }
  return chunks;
}

export function getStringFromBase64String(base64String) {
  try {
    if (_.isNil(base64String)) {
      return null;
    }

    const decodedString = decodeURIComponent(escape(atob(base64String)));

    return decodedString;
  } catch (error) {
    return null;
  }
}

/**
 * With an array of objects -> array1
 * And an array of strig values -> array2
 * Sort array1 given the prop by array2 order
 * Example 1:
 * Array1 = [{field: B, date: 2}, {field: A, date: 1}]
 * Array2 = [A, B]
 * Prop = field
 * Result = [{field: A, date: 1}, {field: B, date: 2}]
 *
 * Example 2:
 * Array1 = [{field: B, date: 2}, {field: A, date: 1}]
 * Array2 = [1, 2]
 * Prop = date
 * Result = [{field: A, date: 1}, {field: B, date: 2}]
 * @param {*} array1
 * @param {*} array2
 * @param {*} prop
 * @returns
 */
export function sortArrayByArrayValues(array1, array2, prop) {
  if (
    _.isNil(array1) ||
    !_.isArray(array1) ||
    _.isEmpty(array1) ||
    _.isNil(array2) ||
    !_.isArray(array2) ||
    _.isEmpty(array2) ||
    _.isNil(prop)
  ) {
    return array1;
  }

  const arrayFiltered = _.filter(array1, function (x) {
    return _.has(x, [prop]);
  });

  return _.sortBy(arrayFiltered, (item) => {
    const index = _.indexOf(array2, item[prop]);
    return index;
  });
}

export function getFormattedValueWithObjectDef({ obj, value }) {
  if (_.isNil(obj) || _.isNil(obj?.type) || _.isNil(value)) {
    return value;
  }

  if (obj.type === "datetime") {
    return formatDateTime(value);
  }

  if (
    (obj?.type === "numeric" ||
      obj?.type === "number" ||
      obj?.type === "string") &&
    obj?.mask?.length
  ) {
    const df = new DecimalFormat(obj.mask);
    return df.format(value);
  }

  
  if (obj.type === "date") {
    return formatDate(value);
  }

  return value;
}

export function formatDateTime(value) {
  if (!_.isNil(value)) {
    const dateToFormat = createStringDateAsUTC(value);
    if (isValid(dateToFormat)) {
      return format(dateToFormat, FRONT_DATE_TIME_PATTERN);
    }
  }
  return value;
}

export function formatDate(value) {
  if (!_.isNil(value)) {
    const dateToFormat = safeFormattingDate(DATE_PATTERN, FRONT_DATE_PATTERN, value); 
    return dateToFormat;
    
  }
  return value;
}

export function normalizeUrlParameters(url) {
  const regex = /([^:])\/\//g;
  return url.replace(regex, "$1/");
}

export const parseNumberWithDotAsDecimalSeparator = (str) => {
  if (str.includes(",")) {
    // Encontrar la última aparición de una coma
    const lastCommaIndex = str.lastIndexOf(",");

    // Reemplazar todos los puntos por vacío excepto el último antes de la coma
    const beforeComma = str.slice(0, lastCommaIndex).replace(/\./g, "");
    const afterComma = str.slice(lastCommaIndex).replace(",", ".");
    const normalizedStr = beforeComma + afterComma;

    // Convertir la cadena normalizada a número
    const number = parseFloat(normalizedStr);

    return isNaN(number) ? null : number;
  } else {
    // Si no hay coma, solo convertir el número
    const number = parseFloat(str);
    return isNaN(number) ? null : number;
  }
};
